<div class="d-flex align-items-center ms-1 ms-lg-3 dropdown-language" data-kt-menu-trigger="click"
     data-kt-menu-placement="bottom-start">
    <div [class]="customStyle">
        <i class="fa fa-bell" [ngClass]="alarmClassName" style="font-size: 16px"></i>
    </div>
</div>
<div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
    data-kt-menu="true" style="min-width: 275px; width: 275px !important;">
    <div class="menu-item px-3">
        <div class="connection-header px-3">
            <div class="connection-header__title">
                <i class="fa-solid fa-arrow-right-arrow-left"></i>
                {{ 'Connection' | localize }}
            </div>
            <div class="connection-header__time">
                <div class="connection-time-label">
                    {{ 'LastCheck' | localize }}
                </div>
                <div class="connection-time">
                    {{ lastCheckTime.format('DD MMM, HH:mm:ss') }}
                </div>
            </div>
        </div>
    </div>
    <div class="menu-item px-3">
        <a href="javascript:;" class="menu-link px-3">
                     <span class="connection-item" [ngClass]="isEpiasConnected ? 'success' : 'danger'">
                         <i class="fa" [ngClass]="isEpiasConnected ? 'fa-check' : 'fa-xmark'"></i>
                     </span>
            {{ 'EPIAS' | localize }}
        </a>
    </div>
    <div class="menu-item px-3">
        <a href="javascript:;" class="menu-link px-3">
                    <span class="connection-item" [ngClass]="isTradeBotsConnected ? 'success' : 'danger'">
                        <i class="fa" [ngClass]="isTradeBotsConnected ? 'fa-check' : 'fa-xmark'"></i>
                    </span>
            {{ 'TradeBots' | localize }}
        </a>
    </div>
    <div class="menu-item px-3">
        <a href="javascript:;" class="menu-link px-3">
                    <span class="connection-item" [ngClass]="isLiveConnectionActive ? 'success' : 'danger'">
                        <i class="fa" [ngClass]="isLiveConnectionActive ? 'fa-check' : 'fa-xmark'"></i>
                    </span>
            {{ 'LiveConnection' | localize }}
        </a>
    </div>
    <div class="menu-item px-3">
        <div class="px-3">
            <div class="company-header">
                <i class="fa-solid fa-wifi"></i>
                <span>{{ 'CompanyConnectionStatus' | localize }}</span>
            </div>
            <ul class="list-group">
                <ng-container *ngFor="let item of companies">
                    <li class="list-group-item">
                        <span class="connection-item" [ngClass]="item.serviceStatus === 'Connected' ? 'success' : 'danger'">
                            <i class="fa" [ngClass]="item.serviceStatus === 'Connected' ? 'fa-check' : 'fa-xmark'"></i>
                        </span>
                        <div class="company-name">
                            <div class="company-name__title">{{ item.companyName }}</div>
                            <div class="company-name__desc">{{ item.companyDesc | titlecase }}</div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
