import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { SignalrConnectionStatus } from "@app/shared/core/real-time/signalr-connection-status.enum";
import { forkJoin } from "@node_modules/rxjs";
import { CompaniesServiceProxy, CompanyDto, IntradayDataServiceProxy, } from "@shared/service-proxies/service-proxies";
import { CompanyStatusItem } from "@app/main/intraday/components/my-offers/my-offers.model";
import { ConnectionStatus } from "@app/shared/layout/topbar/system-status.models";
import { SystemStatusModel } from "@app/shared/layout/topbar/system-status.model";
import * as moment from "@node_modules/moment";

@Component({
    selector: 'app-system-status',
    styleUrls: ['./system-status.component.scss'],
    templateUrl: './system-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemStatusComponent implements OnInit {
    @Input() customStyle: any;

    alarmClassName: string;
    statusLiveEventTimer: number;
    isEpiasConnected: boolean;
    isTradeBotsConnected: boolean;
    isLiveConnectionActive: boolean;
    companies: CompanyStatusItem[] = [];
    lastCheckTime = moment();

    constructor(
        private readonly companiesService: CompaniesServiceProxy,
        private readonly intradayDataServiceProxy: IntradayDataServiceProxy,
        private readonly cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
        this.registerCompanies();
        this.registerLiveStatus();
        this.registerEventListener();
    }

    private registerLiveStatus() {
        abp.event.on('app.signalr.connection-status', status => {
            this.isLiveConnectionActive = status === SignalrConnectionStatus.Connected;
            this.lastCheckTime = moment();
            this.registerAlarmClassName();
            this.cdr.markForCheck();
        });
    }

    private registerCompanies() {
        forkJoin(this.companiesService.getAllForIntraday(), this.intradayDataServiceProxy.getServicesHealthStatus(), this.intradayDataServiceProxy.getIntradayHealthStatus())
            .subscribe(([companies, status, systemHealth]) => {
                this.registerCompanyItems(companies);
                this.registerCompanyStatus(status.epiasHealthStatus as ConnectionStatus, status.wsHealthStatus as ConnectionStatus);
                this.isTradeBotsConnected = systemHealth.status;
                this.registerAlarmClassName();
                this.lastCheckTime = moment();
                this.cdr.markForCheck();
            });
    }

    private registerEventListener() {
        abp.event.on('abp.dispatcher.connectionStatus', (data: SystemStatusModel) => {
            this.lastCheckTime = moment();
            const wsConnections = Object.fromEntries(data.serviceConnections.map(i => [i.connection, i.status]));
            const serviceConnections = Object.fromEntries(data.serviceConnections.map(i => [i.connection, i.status]));

            this.registerCompanyStatus(serviceConnections as ConnectionStatus, wsConnections as ConnectionStatus);

            if (this.statusLiveEventTimer) {
                clearTimeout(this.statusLiveEventTimer);
            }

            this.statusLiveEventTimer = setTimeout(() => {
                this.isEpiasConnected = false;
                this.isTradeBotsConnected = false;
                for (let company of this.companies) {
                    company.serviceStatus = 'Disconnected';
                }
                this.cdr.markForCheck();
            }, 5 * 4 * 1000) as any // 4 event time

            this.isTradeBotsConnected = data.system.status === 'OK';

            this.registerAlarmClassName();

            this.cdr.markForCheck();
        });
    }

    private registerCompanyItems(companies: CompanyDto[]) {
        this.companies = companies
            .map(({ id, etsoCode, name, description }) => ({
                companyId: id,
                etsoCode,
                serviceStatus: null,
                wsStatus: null,
                companyName: name,
                companyDesc: description
            }))
            .sort((a, b) => a.companyName.localeCompare(b.companyName));
    }

    private registerCompanyStatus(serviceConnections: ConnectionStatus, wsConnections: ConnectionStatus) {
        const companyServiceStatuses = Object.fromEntries(this.getEtsoStatusMap(serviceConnections));
        const companyWsStatuses = Object.fromEntries(this.getEtsoStatusMap(wsConnections));

        for (let company of this.companies) {
            company.serviceStatus = companyServiceStatuses[company.etsoCode];
            company.wsStatus = companyWsStatuses[company.etsoCode];
        }

        this.isEpiasConnected = serviceConnections.PublicBoard === 'Connected' || wsConnections.PublicBoard === 'Connected';
    }

    private registerAlarmClassName() {
        this.alarmClassName = this.getAlarmClassName();
    }

    private getAlarmClassName() {
        if (!(this.isTradeBotsConnected && this.isEpiasConnected && this.isLiveConnectionActive)) {
            return 'error';
        }

        const allCompaniesConnected = this.companies.every(x => x.serviceStatus);
        const noCompaniesConnected = this.companies.every(x => !x.serviceStatus);

        switch (true) {
            case allCompaniesConnected:
                return 'success';
            case noCompaniesConnected:
                return 'error';
            default:
                return 'intermediate';
        }
    }

    private getEtsoStatusMap(serviceConnections: ConnectionStatus) {
        return Object.entries(serviceConnections)
            .filter(([key]) => key.endsWith('Epias'))
            .map(([key, status]) => {
                const etsoCode = key.split('-')[2];
                return [etsoCode, status];
            })
    }
}
