import { ChangeDetectorRef, Component, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DataTableColumnType, DataTableData } from "@app/shared/common/data-table/DataTableModels";
import * as _ from "lodash";
import * as oh from '@shared/helpers/ObjectHelper';

@Component({
    selector: "app-data-table",
    templateUrl: "./data-table.component.html",
    styleUrls: ["./data-table.component.scss"]
})
export class DataTableComponent extends AppComponentBase implements OnChanges {

    protected readonly DataTableColumnType = DataTableColumnType;
    protected readonly OH = oh;

    @Input() data: DataTableData;
    @Input() sortingIndex?: number;
    @Input() isHoverable?: boolean = true;

    constructor(injector: Injector, private _cdr: ChangeDetectorRef) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.data) {
            this.setData(this.sortingIndex);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["data"]) {
            this.setData();
        }

        if (changes["sortingIndex"]) {
            this.setData(changes["sortingIndex"].currentValue);
        }
    }

    setData(sortingColumnIndex?: number): void {
        if (sortingColumnIndex) {
            this.sort(sortingColumnIndex);
        }

        this._cdr.detectChanges();
    }

    // WARN: Not tested yet.
    sort(columnIndex: number): void {
        let header = this.data.headers[columnIndex];
        if (!header.isHidden && header.sortable) {
            if (this.sortingIndex !== undefined && this.sortingIndex !== null) {
                if (this.sortingIndex === columnIndex) {
                    this.data.rows = _.orderBy(this.data.rows, (x) => x.columns[this.sortingIndex].value, "desc");
                    header.class.replace("asc", "");
                    header.class += " desc";
                } else {
                    this.data.rows = _.orderBy(this.data.rows, (x) => x.columns[columnIndex].value, "asc");
                    header.class.replace("desc", "");
                    header.class += " asc";
                }
            } else {
                this.data.rows = _.orderBy(this.data.rows, (x) => x.columns[columnIndex].value, "asc");
                header.class += " asc";
            }

            this.sortingIndex = columnIndex;
        }
    }
}
