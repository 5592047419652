<div [id]="id + 'CustomSelectContainer'" (focusout)="onFocusout()" class="custom-select">
    <button (click)="open()" [disabled]="disabled" [class]="buttonClassName" class="btn btn-sm btn-secondary d-flex align-items-center justify-content-between gap-2">
        <img *ngIf="isButtonIconLeft" [src]="getButtonIconSrc()" class="icon h-20px w-20px" alt="icon"/>
        <span [class]="getButtonTextClass()" class="text-nowrap fs-7 fw-bold">{{ getButtonText() }}</span>
        <img *ngIf="!isButtonIconLeft" [src]="getButtonIconSrc()" class="icon h-20px w-20px" alt="icon"/>
    </button>
    <div class="custom-select-menu bg-white shadow rounded p-2 mh-300px min-w-250px" tabindex="-1" style="visibility: hidden;">
        <div *ngFor="let item of items" (click)="select(item)" class="custom-select-item d-flex align-items-center bg-hover-light rounded cursor-pointer gap-2 p-2">
            <img *ngIf="item.iconSrc" [src]="item.iconSrc" class="icon h-20px w-20px" alt="icon"/>
            <span class="text-hover-inverse-light bg-hover-light fw-bold">{{ item.label }}</span>
        </div>
        <div *ngIf="!items || items.length === 0" class="d-flex align-items-center justify-content-center">
            <span class="fw-bold">{{l("NoData")}}</span>
        </div>
    </div>
</div>
